import React from 'react';
import { Stack } from '@mui/material';
import WhyGoyolo from '@/components/desktop/WhyGoyolo';
import { style } from '@/styles/globals';
import Achievements from '@/components/desktop/Achievements';
import GoyoloPartner from '@/components/desktop/GoyoloPartner/desktop';
import GoyoloInvestor from '@/components/desktop/GoyoloInvestor/desktop';
import GoyoloArticle from '@/components/desktop/GoyoloArticle/desktop';
import GoyoloLinks from '@/components/desktop/GoyoloLinks';
import BannerDiscount from '@/components/BannerDiscount';
import WrapperContainer from '@/components/WrapperContainer';

const HomePageContent = ({ headerTabs }: { headerTabs?: number }) => (
  <Stack
    spacing="64px"
    sx={{
      background: style.color.white4,
      padding: {
        xs: '0 20px',
        md: '0 144px',
      },
      paddingTop: '32px !important',
      paddingBottom: '45px!important',
    }}
  >
    <WrapperContainer>
      <BannerDiscount
        isDesktop
        vehicleType={headerTabs === 0 ? 'flight' : 'bus'}
      />
      <WhyGoyolo />
      <Achievements />
      <GoyoloPartner />
      <GoyoloInvestor />
      <GoyoloArticle />
      <GoyoloLinks />
    </WrapperContainer>
  </Stack>
);

export default HomePageContent;
