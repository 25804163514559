import React from 'react';
import styled from '@mui/styled-engine';
import { Button, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import { clearFilterFlight, resetFilter } from '@/slices/filterFlight/filterFlightSlice';
import { resetSortingFlight } from '@/slices/sortingFlight/sortingFlightSlice';
import { resetSelectedBaggage } from '@/slices/baggage/slices/baggageSlice';
import {
  removeFlight,
  removeReturnFlight,
} from '@/slices/selectedFlight/slices/selectedFlightSlice';
import { getParamsFlightMapping } from '@/utils/paramsMapping';
import { useAppDispatch, useAppSelector } from '@/utils/hook';
import type { IFlightResultItem } from '../SearchFlightResultItem/index.d';
import { addAllPoints, adjustAllPassengers as adjustFlightPassenger, adjustSeatClass } from '@/slices/area/slices/searchFlightFormSlice';
import { setFilterFlight } from '@/slices/filterFlight/filterFlightSlice';
import { setSelectedDate } from '@/slices/calendar/calendarSlice';
import { IPassengerSearchForm } from '@/slices/passenger/slices/type.d';
import { SeatClassType } from '../ChooseSeatClass/type';
import { style as globalStyle } from '@/styles/globals';
import { pr } from '@/utils/pxToRem';
// import { resetTimeoutSearchFlight } from '@/utils/eventEmitter';
import { LAST_SEARCH_FIND_ROUTE_FLIGHT } from '@/utils/constants';
import { defaultAirlines } from '@/components/FilterAirline/desktop';
import RedirectAlert from '@/components/RedirectAlert';
import { useBoolean } from '@/utils/hooks';
import { ILangObj } from '@/slices/goyoloSeo/sliceType';
// import {defaultAirlines} from '';
const StyledButton = styled(Button)({
  background: 'rgba(33, 33, 33, 0.08)',
  display: 'flex',
  height: 46,
  cursor: 'pointer',
  overflow: 'hidden',
  boxSizing: 'border-box',
  justifyContent: 'center',
  alignItems: 'center',
  transitionDelay: '0.1s',
  margin: `${globalStyle.spacing.inputToButton} 0 ${globalStyle.spacing.inputToButton} 0`,
  '&:hover': {
    background: '#EEF2F4',
  },
});

const FindRouteButton = ({
  onSearchClick,
  tmpStartPoint,
  tmpToPoint,
  tmpSelectedDate,
  tmpPassenger,
  tmpSeatClass,
  isShowCalendarVietNam,
  filterAirline,
  style,
  seoStyle,
  isVcms,
  lang,
}: {
  onSearchClick?: () => void;
  tmpStartPoint: IFlightResultItem | null;
  tmpToPoint: IFlightResultItem | null;
  tmpSelectedDate: any;
  tmpPassenger: IPassengerSearchForm;
  tmpSeatClass: SeatClassType;
  isShowCalendarVietNam: boolean,
  filterAirline?: any,
  style?: React.CSSProperties,
  seoStyle?: boolean,
  isVcms?: boolean,
  lang?: ILangObj,
}) => {
  const dispatch = useAppDispatch();
  const { startPointHome, toPointHome } = useAppSelector((state) => state.searchFlightForm);
  const vcms = !!isVcms;
  const router = useRouter();
  const [open, setOpen] = useBoolean(false);
  const onSearchButtonClick = () => {
    if (toPointHome?.id === startPointHome?.id) {
      setOpenAlert(true);
      return;
    }

    if (
      !tmpStartPoint
      || !tmpToPoint
      || !tmpSelectedDate
      || !tmpPassenger
    ) {
      return;
    }
    dispatch(removeFlight());
    dispatch(removeReturnFlight());

    dispatch(addAllPoints([
      {
        data: tmpStartPoint,
        type: 'from',
      },
      {
        data: tmpToPoint,
        type: 'to',
      },
    ]));
    dispatch(setSelectedDate({ dateObj: JSON.stringify(tmpSelectedDate) }));
    dispatch(adjustSeatClass({ seatClass: tmpSeatClass || 'PT' }));
    dispatch(adjustFlightPassenger(tmpPassenger));

    dispatch(setFilterFlight({ airline: defaultAirlines }));
    dispatch(resetFilter());
    dispatch(resetSortingFlight());
    dispatch(resetSelectedBaggage());

    // need for seo filter airline
    if (filterAirline) {
      dispatch(setFilterFlight({ airline: filterAirline }));
    }

    if (onSearchClick) {
      onSearchClick();
    }
    // need for filter airlines seo
    if (filterAirline) {
      dispatch(setFilterFlight({ airline: filterAirline }));
    }

    // window.localStorage.setItem(LAST_SEARCH_FIND_ROUTE_FLIGHT, JSON.stringify({
    //   tmpStartPoint,
    //   tmpToPoint,
    //   seatClass: tmpSeatClass,
    //   tmpSelectedDate,
    //   tmpPassenger,
    //   isShowCalendarVietNam,
    // }));
    if (!isVcms) {
      const path = getParamsFlightMapping({
        tmpPassenger,
        tmpSeatClass,
        tmpSelectedDate,
        tmpToPoint,
        tmpStartPoint,
        filterAirline,
        useRootPath: process.env.NODE_ENV !== 'development',
        lang,
      });
      router.push(path);
    }
  };

  const [openAlert, setOpenAlert] = React.useState(false);
  const dialogContent = lang?.SearchBar?.flightsTab?.duplicationArea || 'Điểm đi và đến đang được chọn trùng nhau. Bạn vui lòng chọn địa điểm khác nhé!';
  const paddingVex = seoStyle ? {
    padding: '17px 33px !important',
    width: 'initial !important',
  } : {};
  return (
    <>
      <StyledButton
        disabled={!tmpStartPoint || !tmpToPoint || !tmpSelectedDate.depart}
        style={{
          backgroundColor: seoStyle ? globalStyle.primary.yellow : globalStyle.color.product3,
          borderRadius: pr`6px`,
          ...style,
        }}
        sx={{
          textTransform: 'inherit',
          color: globalStyle.color.white,
          ...paddingVex,
        }}
        fullWidth
        variant="text"
        onClick={onSearchButtonClick}
      >
        <Typography
          variant="body1"
          style={{ color: seoStyle ? globalStyle.neutral.dark : globalStyle.color.white }}
        >
          {/* {"Tìm kiếm".toUpperCase()} */}
          {lang?.SearchBar?.flightsTab?.search || 'TÌM KIẾM'}
        </Typography>
      </StyledButton>
      <Dialog
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        aria-labelledby="same-location"
        aria-describedby="Same location chosen"
      >
        <DialogTitle id="same-location">{dialogContent}</DialogTitle>
        <div style={{
          textAlign: 'center',
          width: '100%',
          marginBottom: pr`16px`,
        }}
        >
          <Button
            style={{
              background: '#CED4DA',
              width: '90%',
            }}
            onClick={() => setOpenAlert(false)}
          >
            {lang?.global?.close || 'Xong'}
          </Button>
        </div>
      </Dialog>
      <RedirectAlert
        open={open}
        setOpen={setOpen}
        onConfirm={onSearchButtonClick}
        params={{}}
      />
    </>
  );
};

export default FindRouteButton;
