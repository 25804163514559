import { pr } from '@/utils/pxToRem';
import { Grid, Typography, Stack } from '@mui/material';
import React from 'react';
import Image from 'next/image';

const GoyoloInvestors = () => (
  <Grid
    container
    alignItems="center"
    width="100%"
  >
    <Grid
      item
      xs={12}
      sm={12}
      md={5}
    >
      <Stack spacing={pr`16px`}>
        <Typography
          component="h2"
          variant="heading1"
          fontWeight="500"
        >
          Danh sách nhà đầu tư
        </Typography>
        <Typography variant="body2">
          VeXeRe, công ty chủ quản của Goyolo.com, đã được nhiều quỹ đầu tư lớn và
          uy tín trong và ngoài nước đầu tư để phát triển nền tảng hỗ trợ bạn
          một cách tốt nhất trên từng chuyến đi
        </Typography>
      </Stack>
    </Grid>
    <Grid
      item
      xs={12}
      sm={12}
      md={7}
    >
      <Stack
        spacing={pr`23px`}
        flexWrap="wrap"
        justifyContent="space-around"
        marginTop={pr`23px`}
        sx={{
          ml: {
            xs: '-10px',
            md: '20px',
            lg: '50px',
          },
        }}
      >
        <Stack
          spacing={pr`23px`}
          flexWrap="wrap"
          justifyContent="space-around"
          sx={{
            p: {
              xs: 0,
              md: '0 40px',
            },
          }}
          direction="row"
        >
          <Image
            src="https://storage.googleapis.com/goyolo-production/investor-woowa.png"
            alt="logo"
            width={100}
            height={72}
            objectFit="contain"
          />
          <Image
            src="https://storage.googleapis.com/goyolo-production/investor-pixvc.png"
            alt="logo"
            width={100}
            height={72}
            objectFit="contain"
          />
          <Image
            src="https://storage.googleapis.com/goyolo-production/investor-av.png"
            alt="logo"
            className="lazyload"
            width={100}
            height={72}
            objectFit="contain"
          />
          <Image
            src="https://storage.googleapis.com/goyolo-production/investor-cyberagent.png"
            alt="logo"
            width={100}
            height={72}
            objectFit="contain"
          />
        </Stack>
        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent="space-around"
          spacing={pr`24px`}
        >
          <Image
            src="https://storage.googleapis.com/goyolo-production/investor-bonangels.png"
            alt="logo"
            width={100}
            height={72}
            objectFit="contain"
          />
          <Image
            src="https://storage.googleapis.com/goyolo-production/investor-spiral.png"
            alt="logo"
            width={100}
            height={72}
            objectFit="contain"
          />

          <Image
            src="https://storage.googleapis.com/goyolo-production/investor-ncore.png"
            alt="logo"
            width={100}
            height={72}
            objectFit="contain"
          />
        </Stack>
      </Stack>
    </Grid>
  </Grid>
);

export default GoyoloInvestors;
