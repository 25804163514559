import React from 'react';
import Image from 'next/image';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { style } from '@/styles/globals';
import { pr } from '@/utils/pxToRem';
import { Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';

interface AchievementsItemProps {
  title: string;
  subtitle: string;
  iconUrl: string;
}

const StyledTab = styled(Tab)`
  max-width: 200px;
  box-shadow: -1px 2px 10px rgb(7 65 57 / 15%) !important;
  margin-left: 12px;
  overflow: hidden;
  padding: 0;
  height: 240px;
  margin-top: 10px;
  background: #ffffff;
  margin-bottom: 4px;
  text-transform: inherit;
  cursor: inherit;
`;

const AchievementsItem: React.FC<AchievementsItemProps> = ({
  title,
  subtitle,
  iconUrl,
}) => (
  <Stack
    spacing={pr`16px`}
    sx={{
      width: '200px!important',
      height: '240px!important',
      boxSizing: 'border-box',
      borderRadius: '6px',
      padding: pr`16px`,
    }}
  >
    <Image
      src={iconUrl}
      alt="banner"
      height={70}
      width={70}
      objectFit="contain"
    />
    <Stack spacing={pr`8px`}>
      <Typography
        variant="title"
        fontWeight="500"
        color={style.color.black1}
      >
        {title}
      </Typography>
      <Typography
        textAlign="center"
        variant="body2"
      >
        {subtitle}
      </Typography>
    </Stack>
  </Stack>
);

const Achievements = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={pr`22px`}
    >
      <Typography
        component="h2"
        fontWeight="500"
        variant="heading1"
      >
        Thành tích nổi bật
      </Typography>
      <Grid container>
        <Grid
          item
          xs={12}
          marginLeft={{
            xs: '-8px!important',
            sm: 0,
          }}
          md={12}
        >
          <Box sx={{}}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              sx={{
                '& .MuiTabs-indicator': {
                  display: 'none',
                },
                '& .MuiSvgIcon-root': {
                  background: '#EFF2F5',
                  borderRadius: '50%',
                  padding: '6px',
                },
                '& .Mui-disabled': {
                  display: 'none',
                },
              }}
              aria-label="scrollable force tabs example"
            >
              <StyledTab
                label={(
                  <AchievementsItem
                    iconUrl="https://storage.googleapis.com/goyolo-production/success-1.png"
                    title="Giải Nhất"
                    subtitle="Cuộc thi Đổi mới sáng tạo TP.HCM 2018 do UBND TP.HCM tổ chức"
                  />
                )}
              />
              <StyledTab
                label={(
                  <AchievementsItem
                    iconUrl="https://storage.googleapis.com/goyolo-production/success-2.png"
                    title="Giải Nhất"
                    subtitle="Cuộc thi Echelon Ignite Vietnam 2014 do Echelon Vietnam tổ chức"
                  />
                )}
              />
              <StyledTab
                label={(
                  <AchievementsItem
                    iconUrl="https://storage.googleapis.com/goyolo-production/success-3.png"
                    title="Giải Nhất"
                    subtitle="Cuộc thi Startup Việt 2016 do báo Vnexpress tổ chức"
                  />
                )}
              />
              <StyledTab
                label={(
                  <AchievementsItem
                    iconUrl="https://storage.googleapis.com/goyolo-production/success-4.png"
                    title="Giải Nhất"
                    subtitle="Cuộc thi Giải thưởng sáng tạo TP.HCM 2019 do UBND TPHCM xét tặng"
                  />
                )}
              />
              <StyledTab
                label={(
                  <AchievementsItem
                    iconUrl="https://storage.googleapis.com/goyolo-production/success-5.png"
                    title="Giải Nhất"
                    subtitle="Cuộc thi Mekong Business Challenge 2014 hỗ trợ bởi McKinsey & Company, Fusion Partners,... "
                  />
                )}
              />
              <StyledTab
                label={(
                  <AchievementsItem
                    iconUrl="https://storage.googleapis.com/goyolo-production/success-6.png"
                    title="Giải Nhì"
                    subtitle="Cuộc thi Start-up Wheel 2014 do TT Hỗ trợ Thanh niên khởi nghiệp TP.HCM & Sở KH&CN TP.HCM tổ chức"
                  />
                )}
              />
              <StyledTab
                label={(
                  <AchievementsItem
                    iconUrl="https://storage.googleapis.com/goyolo-production/success-7.png"
                    title="Giải Ba"
                    subtitle="Cuộc thi Nhân Tài Đất Việt 2015 do Tập đoàn bưu chính viễn thông & Bộ Khoa Học Công nghệ tổ chức"
                  />
                )}
              />
            </Tabs>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Achievements;
