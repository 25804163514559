import { style } from '@/styles/globals';
import {
  Link, Typography, Box, Container,
} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { styled } from '@mui/system';
import { pr } from '@/utils/pxToRem';
import { useEffect } from 'react';
import { BarWrapperDiv } from '../HeaderBar';
import WrapperContainer from '@/components/WrapperContainer';

const StyledFooter = styled('div')`
  bottom: 0;
  width: 100%;
  background: ${style.color.white4};
`;
const StyledDiv = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: start;
`;
const StyledLowerDiv = styled('div')`
  text-align: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background: ${style.color.white2};
  width: ${pr`1152px`};
  flex-direction: column;
`;
const FullWidthDiv = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2em 0;
`;
const StyledVerticalDiv = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledLink = styled(Link)`
  display: block;
  color: ${style.color.black2};
  text-decoration: none;
  margin-bottom: ${pr`8px`};
`;
const StyledIconLink = styled(Link)`
`;
const StyledTypography = styled(Typography)`
  color: ${style.color.black1};
  margin-bottom: 0.5em;
`;
const FooterWrapperDiv = styled(BarWrapperDiv)`
border-top: 1px solid ${style.color.black6};
`;

const FooterBar = () => {
  useEffect(() => {
    document.body.style.background = style.color.white2;
    return () => { document.body.style.background = ''; };
  }, []);

  return (
    <FooterWrapperDiv>
      <StyledFooter>
        <Container
          maxWidth="maxWidth"
          sx={{
            padding: {
              maxWidth: '32px 16px',
              minWidth: '32px 16px',
              sm: '32px 16px ',
              xs: '32px 16px',
            },
          }}
        >
          <StyledDiv>
            <StyledVerticalDiv>
              <img
                src={process.env.NEXT_PUBLIC_LOGO_LINK}
                alt="logo"
                className="lazyload"
                style={{
                  height: pr`50px`,
                }}
              />
              <StyledDiv>
                <StyledIconLink
                  rel="nofollow"
                  target="_blank"
                  href="https://www.facebook.com/Goyolocom-103117924769137"
                >
                  <FacebookIcon />

                </StyledIconLink>
                <StyledIconLink
                  rel="nofollow"
                  target="_blank"
                  href="https://www.linkedin.com/company/goyolo-com/about"
                >
                  <LinkedInIcon />

                </StyledIconLink>
                <StyledIconLink
                  rel="nofollow"
                  target="_blank"
                  href="https://www.youtube.com/channel/UCt3zekKclF8xDdF0aCeZQrw"
                >
                  <YouTubeIcon />

                </StyledIconLink>
              </StyledDiv>
            </StyledVerticalDiv>
            <div>
              <StyledTypography>Về Goyolo.com</StyledTypography>
              <StyledLink
                rel="nofollow"
                target="_blank"
                href="/vn/gioi-thieu.vi"
              >
                Giới thiệu Goyolo.com
              </StyledLink>
              <StyledLink
                rel="nofollow"
                target="_blank"
                href="https://careers.vexere.com"
              >
                Tuyển dụng
              </StyledLink>
              <StyledLink
                rel="nofollow"
                target="_blank"
                href="https://blog.vexere.com"
              >
                Tin tức
              </StyledLink>
              <StyledLink
                rel="nofollow"
                target="_blank"
                href="/vn/lien-he-voi-chung-toi.vi"
              >
                Liên hệ với chúng tôi
              </StyledLink>
            </div>
            <div>
              <StyledTypography>Hỗ trợ</StyledTypography>
              <StyledLink
                rel="nofollow"
                target="_blank"
                href="/info/about-us/policy"
              >
                Quy chế Goyolo.com
              </StyledLink>
              <StyledLink
                rel="nofollow"
                target="_blank"
                href="/info/about-us/info-security"
              >
                Chính sách bảo mật thông tin
              </StyledLink>
              <StyledLink
                rel="nofollow"
                target="_blank"
                href="/info/about-us/payment-security"
              >
                Chính sách bảo mật thanh toán
              </StyledLink>
              <StyledLink
                rel="nofollow"
                target="_blank"
                href="/info/about-us/complain-process"
              >
                Quy trình giải quyết tranh chấp & khiếu nại
              </StyledLink>
            </div>
          </StyledDiv>
        </Container>
        <FullWidthDiv sx={{
          width: '100%',
          background: style.color.white2,
        }}
        >
          <StyledLowerDiv style={{ padding: '0 16px' }}>
            <Typography>Công ty TNHH Thương Mại Dịch Vụ VeXeRe</Typography>
            Địa chỉ đăng ký kinh doanh: 8C Chữ Đồng Tử, Phường 7, Quận Tân Bình, Thành Phố Hồ Chí Minh, Việt Nam
            <br />
            Địa chỉ: Lầu 8, Tòa nhà CirCO, 222 Điện Biên Phủ, Quận 3, TP. Hồ Chí Minh, Việt Nam
            <br />
            Giấy chứng nhận ĐKKD số 0315133726 do Sở KH và ĐT TP. Hồ Chí Minh cấp lần đầu ngày 27/6/2018
            <br />
            Bản quyền © 2022 thuộc về VeXeRe.Com
            <br />
            Logo được thiết kế bởi ATHGroup
          </StyledLowerDiv>
        </FullWidthDiv>
      </StyledFooter>
    </FooterWrapperDiv>
  );
};

export default FooterBar;
