import FooterBar from '@/components/desktop/FooterBar';
import HeaderBar from '@/components/desktop/HeaderBar';
import LandingPage from '@/containers/Desktop/LandingPage';
import HomePageContent from '@/containers/HomePageContent';
import { resetSelectedBaggage } from '@/slices/baggage/slices/baggageSlice';
import { checkDate } from '@/slices/calendar/calendarSlice';
import { useAppSelector } from '@/utils/hook';
import { styled } from '@mui/system';
import { GetServerSidePropsContext, NextPage } from 'next';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetFilter } from '../../slices/filterFlight/filterFlightSlice';
import { resetSortingFlight } from '../../slices/sortingFlight/sortingFlightSlice';
import { resetFilter as resetBusFilter } from '@/slices/bus/filterBusRoute/filterBusRouteSlice';
import { resetSortingBusRoute } from '@/slices/bus/sortingBusRoute/sortingBusRouteSlice';
import HomepageMetaHeader from './header';
import { resetGlobalAll } from '@/slices/global/globalSlice';

import { useCheckIsMobile } from '@/utils/hooks/use-check-is-mobile';
import dynamic from 'next/dynamic';
import { GoyoloFlexiEntityResponse } from '@/slices/goyoloSeo/sliceType';
import { addImagesPromotion } from '@/slices/strapi/slices/strapiSlice';
import { store } from '@/utils/store';
import { queryFareConfig } from '@/utils/queryFareConfig';
import { addFareConfig } from '@/slices/fareRule/slices/fareRuleSlice';

const FlightMobile = dynamic(() => import('./index'));

const HomePage = styled('div')`
  min-height: 100%;
`;

const Flight: NextPage = (props: any = {}) => {
  const { imagePromotion, fareConfig } = props;
  const dispatch = useDispatch();
  const headerTabs = useAppSelector((state) => state.headerTabs || 0);
  const isMobile = useCheckIsMobile();
  useEffect(() => {
    dispatch(resetFilter());
    dispatch(resetSortingFlight());
    dispatch(resetSelectedBaggage());
    dispatch(resetBusFilter());
    dispatch(resetSortingBusRoute());
    dispatch(checkDate());
    dispatch(resetGlobalAll());
    if (fareConfig) {
      dispatch(addFareConfig(fareConfig));
    }
    if (imagePromotion) {
      dispatch(addImagesPromotion(imagePromotion));
    }
  }, []);

  let label = '';
  switch (headerTabs) {
    case 0:
      label = 'Đặt vé máy bay giá rẻ';
      break;
    case 1:
      label = 'Đặt vé xe khách giá rẻ';
      break;
    default:
      label = 'Đặt vé máy bay giá rẻ';
  }

  if (isMobile) {
    return <FlightMobile />;
  }

  return (
    <HomePage>
      <HomepageMetaHeader isDesktop />
      <HeaderBar />
      <LandingPage title={label} />
      <HomePageContent headerTabs={headerTabs} />
      <FooterBar />
    </HomePage>
  );
};

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const goyoloSeoHeaders = {
    Authorization: `Bearer ${process.env.VCMS_TOKEN}`,
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  const fareConfig = await queryFareConfig();
  let imagePromotion;
  try {
    const goyoloSeoFlexiData = `${process.env.VCMS_BASE_URL}/api/flexi-entities?filters[slug][$eq]=banner-discount&populate=deep`;
    const goyoloFlexiArray = await fetch(goyoloSeoFlexiData, { headers: goyoloSeoHeaders }).then((rps) => rps.json()).then((rps) => rps.data) as GoyoloFlexiEntityResponse[];
    imagePromotion = goyoloFlexiArray?.[0]?.attributes?.jsonAttributes?.[0]?.value;
  } catch (err) { console.log(err); }

  return {
    props: {
      imagePromotion: imagePromotion || null,
      fareConfig,
    },
  };
}

export default Flight;
