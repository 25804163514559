import CustomTabs from '@/components/desktop/CustomTabs';
import WrapperContainer from '@/components/WrapperContainer';
import SearchFlightTab from '@/containers/Desktop/SearchFlightTab';
import SearchBusTab from '@/containers/Bus/SearchBusTab/desktop';
import SearchTrainTab from '@/containers/SearchTrainTab/desktop';
import { style } from '@/styles/globals';
import { pr } from '@/utils/pxToRem';
import BusIcon from '@mui/icons-material/DirectionsBus';
import FlightIcon from '@mui/icons-material/Flight';
import TrainIcon from '@mui/icons-material/Train';
import {
  Box, Stack, Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { useDispatch } from 'react-redux';
import { checkDate, setCalendarType } from '@/slices/calendar/calendarSlice';
import Image from 'next/image';

const ImageContainer = styled(Box)`
  height: 100%;
  top: 0px;
  left: 0px;
  width: 100%;
  
  position: absolute;
`;
const Container = styled(Box)`
  position: relative;
`;
// const LandingPageWrapper = styled(Box)`
//   position: relative;
// `;

interface LandingPageProps {
  title?: string;
  titleComponent?: string;
  filterAirline?: any
}

const LandingPage: React.FC<LandingPageProps> = ({ title, titleComponent, filterAirline }) => {
  const dispatch = useDispatch();

  return (
    <Container sx={{
      height: {
        xs: '500px',
        lg: '374px',
      },
    }}
    >
      <ImageContainer>
        <Image
          src="https://storage.googleapis.com/goyolo-production/desktop-background.png"
          alt="Picture of the author"
          layout="fill"
          objectFit="cover"
        />
      </ImageContainer>

      <WrapperContainer>
        <Stack
          direction="row"
          spacing={{
            maxWidth: pr`24px`,
            xs: pr`16px`,
          }}
        >
          <Box
            sx={{
              width: '100%',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                mt: pr`32px`,
                mb: pr`32px`,
              }}
            >
              <Typography
                component="h1"
                sx={{
                  fontWeight: 500,
                  fontSize: {
                    xs: pr`22`,
                    sm: pr`40px`,
                  },
                  lineHeight: {
                    xs: pr`28px`,
                    sm: pr`40px`,
                  },
                  p: {
                    xs: '0',
                    sm: titleComponent ? '0 120px' : '0 32px',
                  },
                  textAlign: {
                    xs: 'left',
                    sm: 'center',
                  },
                  color: style.color.white4,
                }}
              >
                {title || 'Đặt vé máy bay giá rẻ'}
              </Typography>
            </Box>
            <Box>
              <CustomTabs
                onClickTab={(newTab: string) => {
                  dispatch(setCalendarType({ calendarType: newTab as any }));
                  dispatch(checkDate());
                }}
                labelObjs={[
                  {
                    type: 'flight',
                    icon: <FlightIcon style={{ transform: 'rotate(45deg)' }} />,
                  },
                  {
                    type: 'bus',
                    icon: <BusIcon />,
                  },
                  {
                    type: 'train',
                    icon: <TrainIcon />,
                  },
                ]}
                tabComponent={[<SearchFlightTab
                  filterAirline={filterAirline}
                  key={1}
                />, <SearchBusTab
                  key={2}
                />, <SearchTrainTab
                  key={3}
                />,
                ]}
              />
            </Box>
          </Box>
        </Stack>
      </WrapperContainer>
    </Container>
  );
};
export default LandingPage;
