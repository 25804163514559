import { pr } from '@/utils/pxToRem';
import { Grid, Typography, Stack } from '@mui/material';
import React from 'react';
import Image from 'next/image';

const GoyoloPartner = ({ isHideTitle }: { isHideTitle?: boolean }) => (
  <Grid
    container
    alignItems="center"
    width="100%"
  >
    <Grid
      item
      xs={12}
      sm={12}
      md={5}
    >
      <Stack spacing={pr`16px`}>
        {!isHideTitle && (
          <Typography
            component="h2"
            variant="heading1"
            fontWeight="500"
          >
            +1500 đối tác của chúng tôi
          </Typography>
        )}
        <Typography variant="body2">
          Goyolo.com đã hợp tác bán vé tất cả các phương tiện từ máy bay, xe khách,
          tàu hoả của tất cả các hãng lớn như Vietnam Airlines, Vietjet Air,
          Bamboo Airways, Mai Linh, Kumho, Hải Âu, Sao Việt, Đường sắt Việt
          Nam,...
        </Typography>
      </Stack>
    </Grid>
    <Grid
      item
      xs={12}
      sm={12}
      md={7}
    >
      <Stack
        spacing={pr`23px`}
        marginTop={pr`23px`}
        sx={{
          ml: {
            xs: '-10px',
            md: '20px',
            lg: '50px',
          },
        }}
      >
        <Stack
          spacing={pr`23px`}
          flexWrap="wrap"
          justifyContent="space-around"
          sx={{
            p: {
              xs: 0,
              md: '0 40px',
            },
          }}
          direction="row"
        >
          <Image
            src="https://storage.googleapis.com/goyolo-production/partner-vn.png"
            alt="logo"
            height={34}
            width={100}
            objectFit="contain"
          />
          <Image
            src="https://storage.googleapis.com/goyolo-production/partner-vj.png"
            alt="logo"
            height={34}
            width={100}
            objectFit="contain"
          />
          <Image
            src="https://storage.googleapis.com/goyolo-production/partner-qh.png"
            alt="logo"
            height={34}
            width={100}
            objectFit="contain"
          />
          <Image
            src="https://storage.googleapis.com/goyolo-production/partner-kumho.png"
            alt="logo"
            height={34}
            width={100}
            objectFit="contain"
          />
        </Stack>
        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent="space-around"
          spacing={pr`14px`}
        >
          <Image
            src="https://storage.googleapis.com/goyolo-production/partner-vnr.png"
            alt="logo"
            className="lazyload"
            height={34}
            width={100}
            objectFit="contain"
          />
          <Image
            src="https://storage.googleapis.com/goyolo-production/partner-ml.png"
            alt="logo"
            height={34}
            width={100}
            objectFit="contain"
          />
          <Image
            src="https://storage.googleapis.com/goyolo-production/partner-ha.png"
            alt="logo"
            height={34}
            width={100}
            objectFit="contain"
          />
          <Image
            src="https://storage.googleapis.com/goyolo-production/partner-sv.png"
            alt="logo"
            height={34}
            width={100}
            objectFit="contain"
          />
          <Image
            src="https://storage.googleapis.com/goyolo-production/partner-hm.png"
            alt="logo"
            height={34}
            width={100}
            objectFit="contain"
          />
        </Stack>
      </Stack>
    </Grid>
  </Grid>
);

export default GoyoloPartner;
