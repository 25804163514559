import { IBusResultItem } from '@/containers/Bus/SearchBusTab/SearchBusResultItem/type';
import qs from 'qs';
import { IPassengerSearchForm } from '@/slices/passenger/slices/type';
import { SeatClassType } from '@/containers/SearchFlightTab/ChooseSeatClass/type';
import { TSelectedDate } from '../slices/calendar/index.d';
import { IFlightResultItem } from '../containers/SearchFlightTab/SearchFlightResultItem/index.d';
import { format, isBefore } from 'date-fns';
import { getHostnameFromSeo } from './getHostName';
import { ILangObj } from '@/slices/goyoloSeo/sliceType';

interface IGetParamsFlightMapping {
  tmpStartPoint?: IFlightResultItem;
  tmpToPoint?: IFlightResultItem;
  tmpSelectedDate: TSelectedDate;
  tmpPassenger: IPassengerSearchForm;
  tmpSeatClass: SeatClassType;
  useRootPath?: boolean;
  filterAirline?: string[];
  lang?: ILangObj;
}

interface IGetParamsBusMapping {
  tmpStartPoint?: IBusResultItem;
  tmpToPoint?: IBusResultItem;
  tmpSelectedDate: TSelectedDate;
  tmpPassenger: IPassengerSearchForm;
}

export const getParamsFlightMapping = (props: IGetParamsFlightMapping) => {
  const {
    tmpPassenger,
    tmpSeatClass,
    tmpSelectedDate,
    tmpStartPoint,
    tmpToPoint,
    filterAirline,
    useRootPath,
  } = props;
  let path = '';

  const fromLocation = (tmpStartPoint?.airports || []).length === 1 ? tmpStartPoint?.airports[0].id : tmpStartPoint?.id;
  const toLocation = (tmpToPoint?.airports || []).length === 1 ? tmpToPoint?.airports[0].id : tmpToPoint?.id;

  const params = qs.stringify(
    {
      from: fromLocation,
      to: toLocation,
      date: {
        depart: format(new Date(tmpSelectedDate?.depart), 'yyyy-MM-dd'),
        return: tmpSelectedDate?.return ? format(new Date(tmpSelectedDate?.return), 'yyyy-MM-dd') : undefined,
      },
      passenger: tmpPassenger,
      fare_class: tmpSeatClass,
      airlines: filterAirline,
    },
    {
      encode: false,
      skipNulls: true,
    },
  );

  if (tmpSelectedDate?.depart && tmpSelectedDate?.return) {
    path = `${useRootPath ? process.env.GOYOLO_URL || '' : ''}/route-flight?type=depart&${params}`;
  } else path = `${useRootPath ? process.env.GOYOLO_URL || '' : ''}/route-flight?${params}`;
  return path;
};

export const getParamsFlightMappingVcms = (props: IGetParamsFlightMapping) => {
  const {
    tmpPassenger,
    tmpSeatClass,
    tmpSelectedDate,
    tmpStartPoint,
    tmpToPoint,
    filterAirline,
    useRootPath,
    lang,
  } = props;
  let path = '';

  const fromLocation = (tmpStartPoint?.airports || []).length === 1 ? tmpStartPoint?.airports[0].id : tmpStartPoint?.id;
  const toLocation = (tmpToPoint?.airports || []).length === 1 ? tmpToPoint?.airports[0].id : tmpToPoint?.id;
  const params = qs.stringify(
    {
      from: fromLocation,
      to: toLocation,
      date: {
        depart: tmpSelectedDate?.depart && !isBefore(new Date(format(new Date(tmpSelectedDate?.depart), 'yyyy-MM-dd')), new Date(format(new Date(), 'yyyy-MM-dd'))) ? format(new Date(tmpSelectedDate?.depart), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
        return: tmpSelectedDate?.return ? format(new Date(tmpSelectedDate?.return), 'yyyy-MM-dd') : undefined,
      },
      passenger: tmpPassenger,
      fare_class: tmpSeatClass,
      airlines: filterAirline,
    },
    {
      encode: false,
      skipNulls: true,
    },
  );

  if (tmpSelectedDate?.depart && tmpSelectedDate?.return) {
    path = `${lang?.lang === 'en' ? '/vn/flights/booking.en' : '/vn/ve-may-bay/dat-ve-may-bay.vi'}?type=depart&${params}`;
  } else path = `${lang?.lang === 'en' ? '/vn/flights/booking.en' : '/vn/ve-may-bay/dat-ve-may-bay.vi'}?${params}`;
  return path;
};

export const getParamsBusMapping = (props: IGetParamsBusMapping) => {
  const {
    tmpPassenger,
    tmpSelectedDate,
    tmpStartPoint,
    tmpToPoint,
  } = props;
  let path = '';

  const params = qs.stringify(
    {
      from: tmpStartPoint ? tmpStartPoint.id : '',
      to: tmpToPoint ? tmpToPoint.id : '',
      date: {
        depart: format(new Date(tmpSelectedDate?.depart), 'yyyy-MM-dd'),
        return: tmpSelectedDate?.return ? format(new Date(tmpSelectedDate?.return), 'yyyy-MM-dd') : undefined,
      },
      passenger: tmpPassenger,
    },
    {
      encode: false,
      skipNulls: true,
    },
  );

  if (tmpSelectedDate?.depart && tmpSelectedDate?.return) {
    path = `/bus/route-search?type=depart&${params}`;
  } else path = `/bus/route-search?${params}`;
  return path;
};
