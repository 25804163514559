import { style } from '@/styles/globals';
import { pr } from '@/utils/pxToRem';
import { slugify } from '@/utils/stringConvert';
import {
  Grid, Stack, Typography, Link,
} from '@mui/material';
import React from 'react';

const popularRoutes = [
  {
    from: {
      name: 'Sài Gòn',
      slug: 'sai-gon',
      id: 29,
      stateName: 'Sài Gòn',
    },
    to: {
      name: 'Hà Nội',
      slug: 'ha-noi',
      id: 24,
      stateName: 'Hà Nội',
    },
  },
  {
    from: {
      name: 'Sài Gòn',
      slug: 'sai-gon',
      id: 29,
      stateName: 'Sài Gòn',
    },
    to: {
      name: 'Đà Nẵng',
      slug: 'da-nang',
      id: 15,
      stateName: 'Đà Nẵng',
    },
  },
  {
    from: {
      name: 'Sài Gòn',
      slug: 'sai-gon',
      id: 29,
      stateName: 'Sài Gòn',
    },
    to: {
      name: 'Phú Quốc',
      slug: 'phu-quoc',
      id: 431,
      stateName: 'Kiên Giang',
    },
  },
  {
    from: {
      name: 'Sài Gòn',
      slug: 'sai-gon',
      id: 29,
      stateName: 'Sài Gòn',
    },
    to: {
      name: 'Nha Trang',
      slug: 'nha-trang',
      id: 417,
      stateName: 'Khánh Hòa',
    },
  },
  {
    from: {
      name: 'Sài Gòn',
      slug: 'sai-gon',
      id: 29,
      stateName: 'Sài Gòn',
    },
    to: {
      name: 'Đà Lạt',
      slug: 'da-lat',
      id: 457,
      stateName: 'Lâm Đồng',
    },
  },
  {
    from: {
      name: 'Hà Nội',
      slug: 'ha-noi',
      id: 24,
      stateName: 'Hà Nội',
    },
    to: {
      name: 'Sài Gòn',
      slug: 'sai-gon',
      id: 29,
      stateName: 'Sài Gòn',
    },
  },
  {
    from: {
      name: 'Hà Nội',
      slug: 'ha-noi',
      id: 24,
      stateName: 'Hà Nội',
    },
    to: {
      name: 'Phú Quốc',
      slug: 'phu-quoc',
      id: 431,
      stateName: 'Kiên Giang',
    },
  },
  {
    from: {
      name: 'Hà Nội',
      slug: 'ha-noi',
      id: 24,
      stateName: 'Hà Nội',
    },
    to: {
      name: 'Nha Trang',
      slug: 'nha-trang',
      id: 417,
      stateName: 'Khánh Hòa',
    },
  },
  {
    from: {
      name: 'Hà Nội',
      slug: 'ha-noi',
      id: 24,
      stateName: 'Hà Nội',
    },
    to: {
      name: 'Đà Lạt',
      slug: 'da-lat',
      id: 457,
      stateName: 'Lâm Đồng',
    },
  },
  {
    from: {
      name: 'Cần Thơ',
      slug: 'can-tho',
      id: 13,
      stateName: 'Cần Thơ',
    },
    to: {
      name: 'Hà Nội',
      slug: 'ha-noi',
      id: 24,
      stateName: 'Hà Nội',
    },
  },
  {
    from: {
      name: 'Cần Thơ',
      slug: 'can-tho',
      id: 13,
      stateName: 'Cần Thơ',
    },
    to: {
      name: 'Hải Phòng',
      slug: 'hai-phong',
      id: 27,
      stateName: 'Hải Phòng',
    },
  },
  {
    from: {
      name: 'Cần Thơ',
      slug: 'can-tho',
      id: 13,
      stateName: 'Cần Thơ',
    },
    to: {
      name: 'Đà Nẵng',
      slug: 'da-nang',
      id: 15,
      stateName: 'Đà Nẵng',
    },
  },
];

const popularPoint = [
  {
    to: {
      name: 'Sài Gòn',
      slug: 'sai-gon',
      id: 29,
      stateName: 'Sài Gòn',
    },
  },
  {

    to: {
      name: 'Đà Nẵng',
      slug: 'da-nang',
      id: 15,
      stateName: 'Đà Nẵng',
    },
  },
  {

    to: {
      name: 'Phú Quốc',
      slug: 'phu-quoc',
      id: 431,
      stateName: 'Kiên Giang',
    },
  },
  {

    to: {
      name: 'Hải Phòng',
      slug: 'hai-phong',
      id: 27,
      stateName: 'Hải Phòng',
    },
  },
  {

    to: {
      name: 'Đà Lạt',
      slug: 'da-lat',
      id: 457,
      stateName: 'Lâm Đồng',
    },
  },

  {

    to: {
      name: 'Hà Nội',
      slug: 'ha-noi',
      id: 24,
      stateName: 'Hà Nội',
    },
  },
  {

    to: {
      name: 'Nha Trang',
      slug: 'nha-trang',
      id: 417,
      stateName: 'Khánh Hòa',
    },
  },

  {

    to: {
      name: 'Huế',
      slug: 'hue',
      id: 705,
      stateName: 'Thừa Thiên Huế',
    },
  },
];

const popularAirlines = [
  {
    name: 'Vietnam Airlines',
    slug: 'vietnam-airlines',
  },
  {
    name: 'Vietjet Air',
    slug: 'vietjet-air',
  },
  {
    name: 'Bamboo Airways',
    slug: 'bamboo-airways',
  },
  {
    name: 'Vietravel Airlines',
    slug: 'vietravel-airlines',
  },
  {
    name: 'Pacific Airlines',
    slug: 'pacific-airlines',
  },
];

interface GoyoloLinksItemProps {
  title: string;
  listData: any;
  type: 'route' | 'point' | 'airline';
}

const GoyoloLinksItem: React.FC<GoyoloLinksItemProps> = ({
  title,
  listData,
  type,
}) => (
  <Stack
    spacing={pr`16px`}
  >
    <Typography
      variant="title"
      component="div"
      fontWeight="500"
      color={style.color.black1}
    >
      {title}
    </Typography>
    <Stack spacing={pr`16px`}>
      {(listData || []).map((data: any) => {
        let label = '';
        let url = '';
        switch (type) {
          case 'route':
            label = `Vé máy bay từ ${data.from.name} đi ${data.to.name}`;
            url = `/vn/ve-may-bay/tu-${data.from.slug}${data.from?.slug !== slugify(data.from?.stateName) ? `-${slugify(data.from?.stateName)}` : ''}-di-${data.to.slug}${data.to?.slug !== slugify(data.to?.stateName) ? `-${slugify(data.to?.stateName)}` : ''}.${data.from.id}.${data.to.id}.vi`;
            break;
          case 'point':
            label = `Vé máy bay đi ${data.to.name}`;
            url = `/vn/ve-may-bay/di-${data.to.slug}${data.to?.slug !== slugify(data.to?.stateName) ? `-${slugify(data.to?.stateName)}` : ''}.${data.to.id}.vi`;
            break;
          case 'airline':
            label = data.name;
            url = `/vn/ve-may-bay/${data.slug}.vi`;
            break;
          default:
            break;
        }
        return (
          <Link
            sx={{
              textDecoration: 'none',
              fontWeight: 500,
            }}
            key={label}
            href={url}
            target="_blank"
          >
            {label}
          </Link>
        );
      })}
    </Stack>
  </Stack>
);

const GoyoloLinks = () => (
  <Stack
    alignItems="center"
    justifyContent="center"
    spacing={pr`5px`}
  >
    <Grid
      container
      spacing={pr`27px`}
      width="100%"
    >
      <Grid
        item
        xs={12}
        md={6}
        lg={5}
      >
        <GoyoloLinksItem
          type="route"
          title="Đường bay phổ biến"
          listData={popularRoutes}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
      >
        <GoyoloLinksItem
          type="point"
          title="Điểm đến phổ biến"
          listData={popularPoint}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={3}
      >
        <GoyoloLinksItem
          type="airline"
          title="Hãng bay phổ biến"
          listData={popularAirlines}
        />
      </Grid>

    </Grid>
  </Stack>
);

export default GoyoloLinks;
