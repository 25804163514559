/* eslint-disable @next/next/no-html-link-for-pages */
import WrapperContainer from '@/components/WrapperContainer';
import { style } from '@/styles/globals';
import { pr } from '@/utils/pxToRem';
import { Tab, Tabs, Box } from '@mui/material';
import { styled } from '@mui/system';
// import { VN as VNFlag } from 'country-flag-icons/react/3x2';
import { useRouter } from 'next/router';
import React from 'react';

export const BarWrapperDiv = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  background: ${style.color.white4};
  position:relative;
`;
const HeaderWrapperDiv = styled(BarWrapperDiv)`
  margin-bottom: 2px;
`;
const StyledTabs = styled(Tabs)`
  button {
    text-transform: none;
  };
`;
const StyledDiv = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledHeader = styled(StyledDiv)`
  /* padding: ${pr`3px`} ${pr`16px`}; */
  flex-wrap: wrap;
  width: 100%;
`;
const StyledA = styled('a')`
  color: inherit;
  text-decoration: none;
`;

const pathMapping = [
  '/flight',
  '/bus',
  '/train',
  '/ship',
  '/my-booking',
];

const HeaderBar = () => {
  const router = useRouter();
  const tabValue = pathMapping.findIndex((path) => router.asPath.includes(path));

  return (
    <Box sx={{ background: 'white' }}>
      <WrapperContainer>
        <HeaderWrapperDiv>
          <StyledHeader>
            <StyledDiv>
              <a href="/">
                <img
                  src={process.env.NEXT_PUBLIC_LOGO_LINK}
                  alt="logo"
                  className="lazyload"
                  style={{
                    height: pr`50px`,
                  }}
                />
              </a>
              <StyledTabs
                value={tabValue === -1 ? 0 : tabValue}
                sx={{
                  '& .MuiTabs-flexContainer': {
                    flexWrap: 'wrap',
                  },
                }}
                aria-label="choose transportation mode"
                TabIndicatorProps={{
                  style: {
                    visibility: 'hidden',
                  },
                }}
              >
                <Tab
                  label={(
                    <StyledA href="/flight">
                      Vé máy bay
                    </StyledA>
                  )}
                />
                <Tab
                  label={(
                    <StyledA href="/bus">
                      Vé xe khách
                    </StyledA>
                  )}
                />
                <Tab label="Vé tàu lửa" />
                <Tab label="Vé tàu thủy" />
              </StyledTabs>
            </StyledDiv>
            <StyledDiv>
              <StyledTabs
                value={tabValue === (pathMapping.length - 1) || 0}
                aria-label="choose transportation mode"
                TabIndicatorProps={{
                  style: {
                    visibility: 'hidden',
                  },
                }}
              >
                {/* <Tab
          label="VND"
          icon={<VNFlag />}
          iconPosition="start"
        /> */}
                <Tab
                  style={{
                    border: '1px solid',
                    borderRadius: '0.5em',
                  }}
                  onClick={() => { router.push('/my-booking'); }}
                  label="Vé của tôi"
                />
                {/* <Tab label="Đăng nhập" />
        <Tab label="Đăng ký" /> */}
              </StyledTabs>
            </StyledDiv>
          </StyledHeader>
        </HeaderWrapperDiv>

      </WrapperContainer>

    </Box>
  );
};

export default HeaderBar;
