import { style } from '@/styles/globals';
import { pr } from '@/utils/pxToRem';
import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import React from 'react';

interface TwoButtonProps {
  leftTitle: string;
  leftVariant?: 'text' | 'outlined' | 'contained';
  rightVariant?: 'text' | 'outlined' | 'contained';
  onLeftClick: () => void;
  onRightClick?: () => void;
  rightTitle?: string;
}

const TwoButton: React.FC<TwoButtonProps> = ({
  leftTitle,
  rightTitle,
  leftVariant,
  onLeftClick,
  onRightClick,
  rightVariant,
}) => (
  <Stack
    direction="row"
    spacing={pr`16px`}
    justifyContent="center"
    sx={{
    }}
  >
    <Button
      variant={leftVariant}
      onClick={onLeftClick}
      sx={{
        color: style.color.black2,
        background: style.color.black7,
        height: 44,
        width: rightTitle ? '100%' : '50%',
      }}

    >
      {leftTitle}
    </Button>
    {
      rightTitle && (
      <Button
        variant={rightVariant}
        onClick={onRightClick}
        fullWidth
        color="primary"
        sx={{
          color: 'white',
          height: 44,
        }}
      >
        {rightTitle}
      </Button>

      )
    }
  </Stack>
);

export default TwoButton;
