import { style } from '@/styles/globals';
import { pr } from '@/utils/pxToRem';
import {
  Grid, Stack, Typography, Button, Link,
} from '@mui/material';
import React from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Image from 'next/image';

interface GoyoloArticleItemProps {
  title: string;
  subtitle: string;
  iconUrl: string;
  linkUrl: string;
}

const GoyoloArticleItem: React.FC<GoyoloArticleItemProps> = ({
  title,
  subtitle,
  iconUrl,
  linkUrl,
}) => (
  <Stack
    spacing={pr`16px`}
    direction="row"
    alignItems="center"
    sx={{
      maxHeight: '201px',
      boxSizing: 'border-box',
      background: '#FFFFFF',
      boxShadow: '0px 2px 10px rgba(7, 65, 57, 0.15)',
      borderRadius: '12px',
      p: '32px 16px',
    }}
  >
    <Image
      src={iconUrl}
      alt="banner"
      width={208}
      height={85.5}
      objectFit="contain"
    />

    <Stack spacing={pr`8px`}>
      <Typography
        variant="title"
        fontWeight="500"
        color={style.color.black1}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
      >
        {subtitle}
      </Typography>
      <Stack
        sx={{ ml: '-8px!important' }}
        direction="row"
        justifyContent="left"
        alignItems="center"
      >
        <Button sx={{}}>
          <Link
            sx={{
              display: 'flex',
              textDecoration: 'none',
            }}
            href={linkUrl}
            target="_blank"
            rel="nofollow, noindex"
          >

            <Typography
              variant="contentRegular"
              color={(theme) => theme.palette.product3}
              fontWeight="700"
            >
              Đọc thêm
            </Typography>
            <KeyboardArrowRightIcon
              sx={{ color: (theme) => theme.palette.product3 }}
            />

          </Link>
        </Button>
      </Stack>
    </Stack>
  </Stack>
);

const GoyoloArticle = () => (
  <Stack
    alignItems="center"
    justifyContent="center"
    spacing={pr`16px`}
  >
    <Typography
      component="h2"
      fontWeight="500"
      variant="heading1"
    >
      Những bài báo về VeXeRe - Công ty chủ quản của Goyolo.com
    </Typography>
    <Grid
      container
      justifyContent="center"
      spacing="16px"
      width="100%"
    >
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
      >
        <GoyoloArticleItem
          iconUrl="https://storage.googleapis.com/goyolo-production/news-vtv.png"
          title="VeXeRe - Hệ thống vé xe lớn nhất Việt Nam"
          subtitle="Chương trình Cà phê khởi nghiệp được tổ chức bởi đài truyền hình VTV1"
          linkUrl="https://www.youtube.com/watch?v=du_TpvYVNg0"

        />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
      >
        <GoyoloArticleItem
          iconUrl="https://storage.googleapis.com/goyolo-production/news-tuoitre.png"
          title="Quỹ đầu tư Nhật và Singapore đầu tư vào VeXeRe"
          subtitle="VeXeRe vừa nhận được vòng đầu tư thứ hai từ hai quỹ đầu tư Nhật CyberAgent Ventures và Singapore Pix Vine Capital"
          linkUrl="https://tuoitre.vn/blog/quy-dau-tu-nhat-va-singapore-tiep-suc-vexerecom-767367.htm"

        />
      </Grid>
      <Grid
        sx={{
          mt: {
            md: 0,
            lg: pr`16px`,
          },
        }}
        item
        xs={12}
        md={12}
        lg={6}
      >
        <GoyoloArticleItem
          iconUrl="https://storage.googleapis.com/goyolo-production/news-dantri.png"
          title="VeXeRe gọi vốn thành công từ ba nhà đầu tư châu Á"
          subtitle="VeXeRe đã gọi vốn thành công từ ba nhà đầu tư uy tín ở châu Á: Woowa Brothers, NCORE VENTURES, Access Ventures."
          linkUrl="https://dantri.com.vn/kinh-doanh/cong-ty-co-phan-ve-xe-re-goi-von-thanh-cong-tu-cac-nha-dau-tu-uy-tin-20191225100127703.htm"
        />
      </Grid>
      <Grid
        item
        sx={{
          mt: {
            md: 0,
            lg: pr`16px`,
          },
        }}
        xs={12}
        md={12}
        lg={6}
      >
        <GoyoloArticleItem
          iconUrl="https://storage.googleapis.com/goyolo-production/news-vnexpress.png"
          title="Vexere hỗ trợ 5.000 vé Tết 2021 cho sinh viên"
          subtitle="Chương trình do Vexere phối hợp với các nhà xe, đối tác tổ chức với số tiền tài trợ tương đương ba tỷ đồng."
          linkUrl="https://vnexpress.net/vexere-ho-tro-5-000-ve-tet-2021-cho-sinh-vien-4211920.html"
        />
      </Grid>
    </Grid>
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      marginTop="32px!important"
    >
      <Button sx={{}}>
        <Link
          sx={{
            display: 'flex',
            textDecoration: 'none',
          }}
          href="https://blog.vexere.com/"
          target="_blank"
          rel="nofollow, noindex"
        >

          <Typography
            variant="contentRegular"
            color={(theme) => theme.palette.product3}
            fontWeight="400"
          >
            Xem thêm
          </Typography>
          <KeyboardArrowRightIcon
            sx={{ color: (theme) => theme.palette.product3 }}
          />

        </Link>
      </Button>
    </Stack>
  </Stack>
);

export default GoyoloArticle;
