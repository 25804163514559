import { add, eachMonthOfInterval, format } from 'date-fns';

export const monthYears = () => eachMonthOfInterval({
  start: new Date(),
  end: add(new Date(), {
    months: parseInt(
      process.env.NEXT_PUBLIC_RECENT_SEARCH_MONTH_LIMIT || '6',
      10,
    ),
  }),
}).map((monthYear) => format(monthYear, 'MM-yyyy'));
