import { RouteItemGrid } from '@/containers/Bus/SearchBusTab/desktop';
import { LocationSearchType } from '@/containers/SearchFlightTab/SearchDialog/index.d';
import { style } from '@/styles/globals';
import { pr } from '@/utils/pxToRem';
import {
  Divider, Grid, Stack,
} from '@mui/material';
import React from 'react';
import ChoosePassengerContainer from '../ChoosePassengerContainer';
import ChooseSeatClassContainer from '../ChooseSeatClassContainer';
import FindFlightButton from '../FindFlightButton';
import SelectArea from '../SelectArea';
import SelectDate from '../SelectDate';
import ToggleAreaContainer from '../ToggleArea';

const SearchFlightTab = ({
  onSearchClick,
  filterAirline,
  showFullWidth,
  isSearchRoute,
  isVcms,
}: {
  onSearchClick?: () => void;
  filterAirline?: any;
  onClose?: () => void;
  isShowUpdate?: boolean;
  showFullWidth?: boolean;
  isSearchRoute?: boolean;
  isVcms?: boolean;
}) => (
  <Stack
    id="searchFlightTab"
    sx={{
      p: isSearchRoute ? '12px 0 8px 0' : '12px 26px 8px 16px',
      width: showFullWidth ? '100%' : 'unset',
    }}
    spacing={pr`4px`}
  >
    <Stack
      direction="row"
      spacing={pr`32px`}
      alignItems="center"
      sx={{ marginBottom: '10px' }}
    >
      <ChoosePassengerContainer />
      <ChooseSeatClassContainer />
    </Stack>
    <Grid
      container
      width="100%"
      columnSpacing={{
        xs: '8px',
      }}
    >
      <Grid
        container
        width="100%"
        xs={5}
        // sm={12}
        // lg={5}
        sx={{
          position: 'relative',
          border: `1px solid ${style.color.black6}`,
          backgroundColor: 'white',
          boxSizing: 'border-box',
          borderRadius: '6px',
          marginRight: '8px',
          marginBottom: {
            xs: '8px',
            sm: '8px',
          },
          height: '56px',
        }}
      >
        <RouteItemGrid
          // item
          xs={5.9}
          sm={5.9}
          lg={5.9}
          sx={{ position: 'relative' }}
        >
          <SelectArea type={LocationSearchType.FROM} />
        </RouteItemGrid>
        <Grid
          item
          xs={0.2}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            padding: '9px 0',
            position: 'relative',
            height: '56px',
          }}
        >
          <Divider
            orientation="vertical"
            sx={{
              height: '80%',
              width: '1px',
              position: 'absolute',
              margin: '9px 0',
            }}
          />
          <ToggleAreaContainer />
        </Grid>
        <RouteItemGrid
          // item
          xs={5.9}
          sm={5.9}
          lg={5.9}
          sx={{ position: 'relative' }}
        >
          <SelectArea type={LocationSearchType.TO} />
        </RouteItemGrid>
      </Grid>
      <Grid
        container
        width="100%"
        xs={5}
        // sm={12}
        // lg={5}
        sx={{
          border: `1px solid ${style.color.black6}`,
          backgroundColor: 'white',
          boxSizing: 'border-box',
          borderRadius: '6px',
          height: '56px',
          marginBottom: {
            xs: '8px',
            sm: '8px',
          },
        }}
      >
        <RouteItemGrid
          item
          xs={5.9}
          sm={5.9}
          lg={5.9}
        >
          <SelectDate
            filterAirline={filterAirline}
            type="depart"
          />
        </RouteItemGrid>
        <Grid
          item
          xs={0.2}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            padding: '9px 0',
            position: 'relative',
          }}
        >
          <Divider
            orientation="vertical"
            sx={{
              height: '80%',
              width: '1px',
              position: 'absolute',
              margin: '9px 0',
            }}
          />
        </Grid>
        <RouteItemGrid
          item
          xs={5.9}
          sm={5.9}
          lg={5.9}
        >
          <SelectDate
            filterAirline={filterAirline}
            type="return"
          />
        </RouteItemGrid>
      </Grid>
      <Grid
        item
        container
        xs={1.8}
        sx={{
          // marginBottom: '16px',
          height: '56px',
        }}
      >
        <FindFlightButton
          filterAirline={filterAirline}
          onSearchClick={onSearchClick}
          isVcms={isVcms}
        />
      </Grid>
    </Grid>
  </Stack>
);

export default SearchFlightTab;
