/* eslint-disable array-callback-return */
import { EventEmitter } from 'fbemitter';

const eventEmitter = new EventEmitter();

export const TRIGGER_VALIDATE = 'TRIGGER_VALIDATE';
export const TRIGGER_VALIDATE_BY_KEY = 'TRIGGER_VALIDATE_BY_KEY';
export const TRIGGER_VALIDATE_BY_ARRAY_KEY = 'TRIGGER_VALIDATE_BY_ARRAY_KEY';
export const SET_FORM_VALUE = 'SET_FORM_VALUE ';
export const CLEAR_ANCHOR_POPPER_SELECT = 'CLEAR_ANCHOR_POPPER_SELECT';
export const CLEAR_ANCHOR_POPPER_INPUT = 'CLEAR_ANCHOR_POPPER_INPUT';
export const RESET_TIMEOUT_SEARCH = 'RESET_TIMEOUT_SEARCH';
export const RESET_TIMEOUT_SEARCH_BUS = 'RESET_TIMEOUT_SEARCH_BUS';

// bulk validate form
export const triggerValidateAllForm = async () => new Promise((rs) => {
  let tempIsValid = true;
  eventEmitter.emit(TRIGGER_VALIDATE, (isValid: boolean) => {
    // have multiple form call to this callback, need update final isValid value
    // vd: false && true && true
    tempIsValid = tempIsValid && isValid;
    setTimeout(() => {
      rs(tempIsValid);
    }, 0);
  });
});

export const setValueCustomForm = (name: string, value: any) => {
  eventEmitter.emit(SET_FORM_VALUE, {
    name,
    value,
  });
};

// bulk validate form which is not dirty
export const triggerValidateArrayFormId = async (arrKey: any[]) => Promise.all(
  arrKey.map(
    (key) => new Promise((rs) => {
      eventEmitter.emit(
        `${TRIGGER_VALIDATE_BY_ARRAY_KEY}_${key}`,
        (isValid?: boolean) => {
          rs(isValid);
        },
      );
    }),
  ),
);

// validate form by formId
export const triggerValidateByFormId = async (key: string) => new Promise((rs) => {
  let tempIsValid = true;
  eventEmitter.emit(
    `${TRIGGER_VALIDATE_BY_KEY}_${key}`,
    (isValid: boolean) => {
      tempIsValid = isValid;
      setTimeout(() => {
        rs(tempIsValid);
      }, 0);
    },
  );
});

// export const resetTimeoutSearchFlight = () => {
//   eventEmitter.emit(RESET_TIMEOUT_SEARCH);
// };
// export const resetTimeoutSearchBus = () => {
//   eventEmitter.emit(RESET_TIMEOUT_SEARCH);
// };
export const clearAnchorPopperInput = () => {
  eventEmitter.emit(CLEAR_ANCHOR_POPPER_INPUT);
};
export const clearAnchorPopperSelect = () => {
  eventEmitter.emit(CLEAR_ANCHOR_POPPER_SELECT);
};

export default eventEmitter;
