import { setHeaderTabsValue } from '@/slices/headerTabs/slices/headerTabsSlice';
import { style } from '@/styles/globals';
import { useAppDispatch, useAppSelector } from '@/utils/hook';
import { pr } from '@/utils/pxToRem';
import { Tab, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import { uniqueId } from 'lodash';
import React from 'react';
import type { TabPanelProps, TabsProps } from './index.d';
import { styled } from '@mui/system';

const baseRibbonStyle = `
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    color-scheme: light;
    font-size: 0.875rem;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    line-height: 22px;
    white-space: nowrap;
    box-sizing: border-box;
`;

const RibbonContainer = styled('div')`
  ${baseRibbonStyle}
  margin: 0;
  position: absolute;
  padding: 0 8px;
  border-radius: 2px;
  color: #fa541c!important;
  background: #fa541c!important;
  border-bottom-right-radius: 0;
  z-index: 99;
  padding: 0.2em 0.5em;
  height: unset!important;
  top: -37%;
  right: -5%;
`;
const RibbonText = styled('span')`
  ${baseRibbonStyle}
  color: #fff;
`;
const RibbonCorner = styled('div')`
  ${baseRibbonStyle}
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  width: 8px;
  height: 8px!important;
  color: currentcolor!important;
  background: transparent!important;
  border: 4px solid;
  transform: scaleY(.75)!important;
  transform-origin: top!important;
  right: 0;
  border-color: #d9363e transparent transparent #d9363e;
`;

export const PromotionRibbon = ({ children }: { children: any }) => (
  <RibbonContainer>
    <RibbonText>{children}</RibbonText>
    <RibbonCorner />
  </RibbonContainer>
);

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return value === index ? (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        position: 'absolute',
        width: '100%',
        background: 'white',
        borderRadius: index === 0 ? '0px 6px 6px 6px' : '6px 6px 6px 6px',
      }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  ) : null;
}

const CustomTabs: React.FC<TabsProps> = ({
  isTrain,
  labelObjs,
  variant,
  tabComponent,
  onClickTab,
  seoStyle,
  lang,
}) => {
  const typeMapping: { [key: string]: string } = {
    flight: lang?.SearchBar?.flightsTab?.flight || 'Máy bay',
    bus: 'Xe khách',
    train: 'Tàu hỏa',
  };
  const value = useAppSelector((state) => state.headerTabs);
  const dispatch = useAppDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setHeaderTabsValue(newValue));
  };

  return (
    <Box
      sx={{ width: '100%' }}
    >
      <Box sx={{
        display: 'flex',
        '& .MuiTabs-flexContainer': {
          maxHeight: '76px',
        },
        '& .MuiTabs-root': {
          overflow: 'visible !important',
        },
        '& .MuiTabs-scroller': {
          overflow: 'visible !important',
        },
      }}
      >
        <Tabs
          value={value || 0}
          variant={(variant as any) || 'standard'}
          sx={{
            display: 'flex',
            '& .MuiTabs-flexContainer': {
              maxHeight: '76px',
            },
            '& .MuiTabs-root': {
              overflow: 'visible !important',
            },
            '& .MuiTabs-scroller': {
              overflow: 'visible !important',
            },
          }}
          onChange={handleChange}
          aria-label="transportation tabs"
          TabIndicatorProps={{
            style: {
              background: 'transparent',
            },
          }}
        >
          {labelObjs.map((labelObj) => {
            let disabled = labelObj.type !== Object.keys(typeMapping)[value];
            if (isTrain) {
              disabled = labelObj.type !== 'train';
            }
            const color = !disabled ? seoStyle ? style.primary.blue : style.color.product3 : style.color.white4;
            return (
              <Tab
                icon={labelObj.icon}
                label={(
                  <>
                    {(seoStyle && !isTrain) && (<PromotionRibbon>{lang?.SearchBar?.flightsTab?.discount || 'Giảm 20K/vé'}</PromotionRibbon>)}
                    <Typography
                    // variant="title"
                      color={color}
                      style={{
                        fontSize: pr`16px`,
                      }}
                    >
                      {!labelObj.routeInfo
                        ? typeMapping[labelObj.type] || ''
                        : labelObj.routeInfo(color)}
                    </Typography>
                  </>

                )}
                aria-label={labelObj.ariaName}
                key={uniqueId()}
                onClick={() => onClickTab?.(labelObj.type)}
                sx={{
                  textTransform: 'inherit',
                  background: !disabled ? style.color.white4 : '',
                  color: `${color} !important`,
                  // padding: pr`8px`,
                  padding: '12px 24px',
                  fontSize: '16px',
                  borderRadius: '6px 6px 0px 0px',
                  display: 'flex',
                  flexDirection: 'row',
                  minWidth: '142px',
                  minHeight: '56px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  '& svg': {
                    marginRight: pr`8px`,
                    marginBottom: '0px!important',
                    fontSize: pr`26px`,
                  },
                  overflow: 'visible',
                  '& div': {
                    overflow: 'visible !important',
                  },
                  '& .MuiTabs-scroller': {
                    overflow: 'visible !important',
                  },
                }}
              />
            );
          })}
        </Tabs>
      </Box>
      {tabComponent ? (
        <div
          style={{
            paddingBottom: 0,
          }}
        >
          {labelObjs.map((labelObj, index) => (
            <TabPanel
              value={value}
              key={uniqueId()}
              index={index}
            >
              <div>{tabComponent[index]}</div>
            </TabPanel>
          ))}
        </div>
      ) : null}
    </Box>
  );
};

export default CustomTabs;
