import React, { useEffect, useState } from 'react';

export const useClipboard = (
  // @ts-ignore
  { updateFrequency = 1000 } = {},
  onReadError?: (arg0: { message: string }) => void,
) => {
  const [clipboard, setClipboardContent] = useState('');

  const copyToClipboard = (
    clipboardContent: React.SetStateAction<string>,
    onCopyToClipboard?: (arg0: any) => void,
    onWriteError?: (arg0: { message: string }) => void,
  ) => {
    if (navigator.permissions && navigator.clipboard) {

    // navigator.permissions.query({ name: 'clipboard-write' }).then(({ state }) => {
    //   if (['granted', 'prompt'].includes(state)) {
    //     navigator.clipboard.writeText(clipboardContent as any).then(
    //       () => {
    //         setClipboardContent(clipboardContent);
    //         onCopyToClipboard?.(clipboardContent);
    //       },
    //       onWriteError || (() => {}),
    //     );
    //   } else {
    //     onWriteError?.({ message: 'ClipboardWrite permission has been blocked as the user.' });
    //   }
    // });
    }
    navigator.clipboard.writeText(clipboardContent as any).then(
      () => {
        setClipboardContent(clipboardContent);
        onCopyToClipboard?.(clipboardContent);
      },
      onWriteError || (() => {}),
    );
  };

  useEffect(() => {
    let readClipboardIntervalId: any;
    if (navigator.permissions && navigator.clipboard) {
    // navigator.permissions.query({ name: 'clipboard-read' }).then(({ state }) => {
    //   if (['granted', 'prompt'].includes(state)) {
    //     readClipboardIntervalId = setInterval(() => {
    //       navigator.clipboard.readText().then(
    //         (clipboardContent) => {
    //           setClipboardContent(clipboardContent);
    //         },
    //         onReadError || (() => {}),
    //       );
    //     }, updateFrequency);
    //   } else {
    //     onReadError?.({ message: 'ClipboardRead permission has been blocked as the user.' });
    //   }
    // });
    }
    navigator.clipboard.readText().then(
      (clipboardContent) => {
        setClipboardContent(clipboardContent);
      },
      onReadError || (() => {}),
    );
    return () => {
      if (navigator.clipboard) {
        clearInterval(readClipboardIntervalId);
      }
    };
  }, []);

  return {
    clipboard,
    copyToClipboard,
  };
};
