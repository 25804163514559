import React from 'react';
import ComingSoonMessage from '@/components/ComingSoonMessage';

function SearchTrainTab() {
  return (
    <ComingSoonMessage isDesktop />
  );
}

export default SearchTrainTab;
