import { style as globalStyle } from '@/styles/globals';
import { getParamsBusMapping } from '@/utils/paramsMapping';
import { pr } from '@/utils/pxToRem';
import { Button, Typography } from '@mui/material';
import styled from '@mui/styled-engine';
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import { resetFilter as resetBusFilter } from '@/slices/bus/filterBusRoute/filterBusRouteSlice';
import { resetSortingBusRoute } from '@/slices/bus/sortingBusRoute/sortingBusRouteSlice';
import { setSelectedDate } from '@/slices/calendar/calendarSlice';
import { useAppDispatch, useAppSelector } from '@/utils/hook';
import type { IBusResultItem } from '../SearchBusResultItem/type';
import { useRouter } from 'next/router';
import {
  addAllPoints,
  adjustPassenger as adjustBusPassenger,
} from '@/slices/bus/area/slices/searchBusFormSlice';
import { LAST_SEARCH_FIND_ROUTE_BUS } from '@/utils/constants';

export const FindButton = styled(Button)({
  background: 'rgba(33, 33, 33, 0.08)',
  display: 'flex',
  height: 46,
  cursor: 'pointer',
  overflow: 'hidden',
  boxSizing: 'border-box',
  justifyContent: 'center',
  alignItems: 'center',
  transitionDelay: '0.1s',
  margin: `${globalStyle.spacing.inputToButton} 0 ${globalStyle.spacing.inputToButton} 0`,
  '&:hover': {
    background: '#EEF2F4',
  },
});

const FindRouteButton = ({
  onSearchClick,
  tmpStartPoint,
  tmpToPoint,
  tmpSelectedDate,
  tmpPassenger,
  isShowCalendarVietNam,
  style,
}: {
  onSearchClick?: () => void;
  tmpStartPoint: IBusResultItem | null;
  tmpToPoint: IBusResultItem | null;
  tmpSelectedDate: any;
  tmpPassenger: any;
  isShowCalendarVietNam: boolean;
  style?: React.CSSProperties;
}) => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  const { startPointHome, toPointHome } = useAppSelector(
    (state) => state.searchBusForm,
  );

  const onSearchButtonClick = () => {
    if (startPointHome?.id === toPointHome?.id) {
      setOpenAlert(true);
      return;
    }
    if (!tmpStartPoint || !tmpToPoint || !tmpSelectedDate || !tmpPassenger) {
      return;
    }

    dispatch(
      addAllPoints([
        {
          data: tmpStartPoint,
          type: 'from',
        },
        {
          data: tmpToPoint,
          type: 'to',
        },
      ]),
    );
    dispatch(setSelectedDate({ dateObj: JSON.stringify(tmpSelectedDate) }));
    dispatch(adjustBusPassenger(tmpPassenger));

    dispatch(resetBusFilter());
    dispatch(resetSortingBusRoute());

    if (onSearchClick) {
      onSearchClick();
    }

    const path = getParamsBusMapping({
      tmpPassenger,
      tmpSelectedDate,
      tmpToPoint,
      tmpStartPoint,
    });

    router.push(path);
  };

  const [openAlert, setOpenAlert] = React.useState(false);
  const dialogContent = 'Địa điểm đi và đến đang được chọn trùng nhau. Bạn vui lòng chọn địa điểm khác nhé!';

  return (
    <>
      <FindButton
        disabled={!tmpStartPoint || !tmpToPoint || !tmpSelectedDate.depart}
        style={{
          backgroundColor: globalStyle.color.product3,
          borderRadius: pr`6px`,
          ...style,
        }}
        sx={{
          textTransform: 'inherit',
          color: globalStyle.color.white,
        }}
        fullWidth
        variant="text"
        onClick={onSearchButtonClick}
      >
        <Typography
          variant="body1"
          style={{ color: globalStyle.color.white }}
        >
          TÌM KIẾM
        </Typography>
      </FindButton>
      <Dialog
        open={openAlert}
        onClose={() => setOpenAlert(false)}
      >
        <DialogTitle id="same-location">{dialogContent}</DialogTitle>
        <div
          style={{
            textAlign: 'center',
            width: '100%',
            marginBottom: pr`16px`,
          }}
        >
          <Button
            style={{
              background: '#CED4DA',
              width: '90%',
            }}
            onClick={() => setOpenAlert(false)}
          >
            Xong
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default FindRouteButton;
