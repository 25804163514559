import React from 'react';
import { SxProps, Theme } from '@mui/material';
import { Container } from '@mui/material';

const WrapperContainer = ({ children, sx }: { children: any, sx?: SxProps<Theme>; }) => (

  <Container
    maxWidth="maxWidth"
    sx={{
      padding: {
        maxWidth: '0 16px',
        minWidth: '0 16px',
        sm: '0 16px ',
        xs: '0 16px',
      },
      ...sx,
    }}
  >
    {children}
  </Container>
);

export default WrapperContainer;
