import { style } from '@/styles/globals';
import { pr } from '@/utils/pxToRem';
import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import Image from 'next/image';

interface WhyGoyoloItemProps {
  title: string;
  subtitle: string;
  iconUrl: string;
}

const WhyGoyoloItem: React.FC<WhyGoyoloItemProps> = ({
  title,
  subtitle,
  iconUrl,
}) => (
  <Stack
    spacing={pr`16px`}
    alignItems="center"
  >
    <Image
      src={iconUrl}
      alt="banner"
      width={90}
      height={88}
    />
    <Typography
      variant="title"
      fontWeight="500"
      color={style.color.black1}
    >
      {title}
    </Typography>
    <Typography
      textAlign="center"
      variant="body2"
    >
      {subtitle}
    </Typography>
  </Stack>
);

const WhyGoyolo = () => (
  <Stack
    alignItems="center"
    justifyContent="center"
    spacing={pr`32px`}
  >
    <Typography
      component="h2"
      fontWeight="500"
      variant="heading1"
    >
      Tại sao nên đặt vé qua Goyolo.com?
    </Typography>
    <Grid
      container
      // spacing={pr`27px`}
      width="100%"
    >
      <Grid
        item
        xs={12}
        sm={4}
        lg={4}
      >
        <WhyGoyoloItem
          iconUrl="https://storage.googleapis.com/goyolo-production/why-1.png"
          title="Tất cả phương tiện trong một"
          subtitle="Goyolo.com là nền tảng đặt vé đầu tiên ở Việt Nam cho phép so sánh giá cả, giờ khởi hành, đánh giá chất lượng giữa máy bay, xe khách, và tàu hoả."
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        lg={4}
      >
        <WhyGoyoloItem
          iconUrl="https://storage.googleapis.com/goyolo-production/why-2.png"
          title="Đảm bảo hành trình được xuyên suốt"
          subtitle="Tích hợp hệ thống với toàn bộ các hãng hàng không, xe khách, và tàu hoả, đảm bảo hành trình xuyên suốt."
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        lg={4}
      >
        <WhyGoyoloItem
          iconUrl="https://storage.googleapis.com/goyolo-production/why-3.png"
          title="Giá vé tốt nhất thị trường"
          subtitle="Goyolo.com luôn luôn có các chương trình khuyến mãi hấp dẫn dành cho khách hàng cập nhật liên tục tại ứng dụng Goyolo.com và website Goyolo.com."
        />
      </Grid>
    </Grid>
    <Grid
      container
      justifyContent="center"
      // spacing={pr`27px`}
      width="100%"
    >
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={4}
        marginRight={{
          xs: 0,
          md: '27px',
        }}
      >
        <WhyGoyoloItem
          iconUrl="https://storage.googleapis.com/goyolo-production/why-4.png"
          title="Hỗ trợ nhanh chóng và chu đáo"
          subtitle="Goyolo.com luôn luôn thấu hiểu khách hàng và nỗ lực hỗ trợ bạn mọi lúc mọi nơi, đảm bảo hành trình được trọn vẹn."
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={4}
      >
        <WhyGoyoloItem
          iconUrl="https://storage.googleapis.com/goyolo-production/why-5.png"
          title="Đa dạng hình thức thanh toán"
          subtitle="Phương thức thanh toán đa dạng và an an toàn gồm ví điện tử Momo, Airpay, chuyển khoản ngân hàng, thẻ Visa/Master, tại các cửa hàng tiện lợi hoặc bạn có thể thanh toán sau."
        />
      </Grid>
    </Grid>
  </Stack>
);

export default WhyGoyolo;
